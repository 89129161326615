.contact-social-img img{
    width: 24px;
    height: 24px;
    cursor: pointer;
}

#contact-wrapper{
    margin-bottom: 10%;
}

.get-in-touch-left{
    width: 50%;
}

@media screen and (max-width: 768px) {
    #contact-wrapper{
      flex-direction: column;
      width: 100%;
      gap: 50px;
    }

    .get-in-touch-left{
        width: 100%;
    }
  }