.forgot-label {
    color: #4F4F4F;
}

.forgot-email-color {
    color: #828282 !important;
    font-size: 16px;
}

.main-container {
    /* padding-block: 5vh; */
}

/* Login page */

.forgot-text {
    color: #7E7E80 !important;
 }

.back-icon{
    gap: 15px;
    margin: 0 auto;
}

.outer-container {
    position: relative;
    width: 100%;
    height: 150px;
 }

.inner-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
}