.flex {
    display: flex;
    align-items: center;
}

.new-group-btn {
    border: 1px solid #069514;
    padding-inline: 10px;
    padding-block: 8px;
    border-radius: 6px;
    margin-right: 8px;
    color: #069514;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s all;
}

.new-group-btn:hover {
    box-shadow: 0 0 5px 1px #069514;
    transform: scale(1.1, 1.1);
}

.group-icons {
    width: 38px;
    height: 38px;
    cursor: pointer;
    border-radius: 100px;
    background-color: #8282822e;
}

/* Leave group button */
.leave-btn {
    color: rgb(255 255 255);
    background-color: rgba(130, 130, 130, 1);
    padding-inline: 10px;
    padding-block: 4px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s ease;
}

.leave-btn:hover {
    transform: scale(1.1, 1.1);
}

.leave-btn>img {
    padding-right: 6px;
}

/* Join group button */
.join-btn {
    color: rgb(255 255 255);
    background-color: #069514;
    padding-inline: 10px;
    padding-block: 4px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s ease;
}

.join-btn:hover {
    transform: scale(1.1, 1.1);
}

.join-btn>img {
    padding-right: 6px;
}

.group-members {
    min-width: 4rem;
}

.group-members img {
    width: 11px;
    height: 11px;
}

.discover-card {
    padding: 8px 10px;
    background-color: #3e98461c;
    border-radius: 6px;
}

.discover-description {
    color: #828282;
}

.discover-icon {
    width: 38px;
    height: 38px;
    cursor: pointer;
    border-radius: 100px;
    background-color: #8282822e;
}

.create-group-btn {
    border: none;
    padding: 10px 20px;
    background-color: #069514;
    color: white;
    border-radius: 30px;
    transition: 0.2s ease-in-out;
}

.create-group-btn:hover{
    background-color: #069514d6;
}

@media screen and (max-width: 768px) {

    .discover-description {
        font-size: 14px;
        line-height: 12px;
        text-align: center;
    }

    .discover-card {
        flex-direction: column;
    }

    .discover-icon-wrapper {
        flex-direction: column;
    }

    .discover-card h6 {
        font-size: 18px;
    }

    .discover-description {
        flex-grow: 1;
        padding: 8px 0px;
    }

}