.flex {
    display: flex;
    align-items: center;
}

.about-left{
    color: #828282;
}

.primary-color{
    color: #828282;
    padding: 5px 0px;
 }

.about-sub-header{
    margin-left: 60px;
    color: #828282;
}

.secondary-color{
    color: #333333;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 5px 0px;
}

.sport-betting{
    text-decoration: underline;
    cursor: pointer;
}

.sport-betting:hover{
    color:  #069514;
}

@media screen and (max-width: 767px) {
    .primary-color, .secondary-color, .about-left, h5{
        font-size: 14px;
    }
}