.flex {
    display: flex;
    align-items: center;
}

#copy-img {
    width: 35px;
    height: 35px;
    cursor: pointer;
    transition: 0.3s ease;
}

#copy-img:hover {
    background-color: #82828236;
    transform: scale(1.4, 1.4);
    border-radius: 10px;
}

.section-copy {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px dotted #069514;
    border-radius: 12px;
}

.referal-img{
    width: 360px;
    height: 338px;
}

.qr-img{
    height: 248px;
}