:root {
    /* --right-border: 0.5px solid #E0E0E0; */
    --achievement-width: 26%;
    --achievement-shadow: #a59c9c 0px 0px 20px;
    --achievement-padding-top: 2px;
}

.flex {
    display: flex;
    align-items: center;
}

.email-address {
    color: #828282;
}

.follow-btn {
    padding-inline: 25px;
    margin-right: 18px;
    padding-block: 4px;
}

.dot-btn {
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.dot-btn:hover {
    background-color: #82828250;
    border-radius: 100px;
}

.parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 100%;
    padding-top: 10px;
    gap: 10px;
}

.div1 {
    box-shadow: var(--achievement-shadow);
    /* grid-area: 1 / 1 / 2 / 2; */
    border-radius: 10px;
    font-size: 12px;
    width: var(--achievement-width);
    padding: var(--achievement-padding-top);
}

.div2 {
    box-shadow: var(--achievement-shadow);
    /* grid-area: 1 / 2 / 2 / 3; */
    font-size: 12px;
    border-radius: 10px;
    font-size: 12px;
    width: var(--achievement-width);
    padding: var(--achievement-padding-top);
}

.div3 {
    box-shadow: var(--achievement-shadow);
    /* grid-area: 2 / 1 / 3 / 3; */
    font-size: 12px;
    border-radius: 10px;
    font-size: 12px;
    width: var(--achievement-width);
    padding: var(--achievement-padding-top);
}


.achievement-div1 {
    grid-area: 1 / 1 / 2 / 2;
}

.achievement-div2 {
    grid-area: 1 / 2 / 2 / 3;
    margin-bottom: 20px;
}

.achievement-div3 {
    grid-area: 2 / 1 / 3 / 3;
}

@media (max-width: 768px) {
    .gap-2 {
        gap: 0px; /* Gap for mobile view */
    }
}
.blue {
    color: #756c6c;
    font-size: 18px;
    border-right: var(--right-border);
    padding-right: 20px;
}

.green {
    color: #756c6c;
    font-size: 18px;
    border-right: var(--right-border);
    padding-inline: 20px;
}

.red {
    color: #756c6c;
    font-size: 18px;
    border-right: var(--right-border);
    padding-inline: 20px;
}

.orange {
    color: #756c6c;
    font-size: 18px;
    padding-left: 20px;
}

.discord-container {
    margin-top: 1.5rem;
    padding-left: 10px;
}

.discord-username {
    color: #828282;
    font-size: 14px;
}

.profile-name {
    text-align: left;
    margin-bottom: 0px;
}

/* .Active {
    background-color: #069514c2;
    color: white;
    border-radius: 6px;
} */

.custom-tab {
    background: transparent !important;
    color: white !important;
    border-bottom: 2px solid #069514 !important;
    border-radius: 0px !important;
}


.InActive {
    background-color: unset;
    color: rgb(255, 255, 255);
    border-radius: 6px;
}

.chart-heading {
    color: red;
    text-align: center;
    text-decoration: underline;
}

.toggle-bets {
    text-decoration: none;
}

.toggle-bets:hover {
    color: rgb(114, 108, 108);
}


.question-stats {
    color: rgb(79, 70, 229);
    font-size: 1.25rem;
}

.portfolio-header {
    background-color: #333;
    padding: 5px 7px;
    border-radius: 6px;
}

.question-bet-btn {
    width: 45px;
    height: 27px;
    color: white;
    padding: 4px 4px;
    border-radius: 8px;
    border: 1px solid #2D9CDB;
    background-color: rgba(0, 0, 0, 0.792);
}

.question-bet-btn:hover {
    background-color: #333;
}

.question-edit-btn {
    width: 50px;
    height: 27px;
    color: white;
    border-radius: 8px;
    border: 1px solid green;
    background-color: rgba(0, 0, 0, 0.792);
}

.question-delete-btn:hover {
    background-color: #333;
}

.question-delete-btn {
    width: 60px;
    height: 27px;
    padding-inline: 4px;
    color: white;
    border-radius: 8px;
    border: 1px solid red;
    background-color: rgba(0, 0, 0, 0.792);
}

.question-edit-btn:hover {
    background-color: #333;
}

.bet-list {
    font-size: 14px;
    cursor: pointer;
    padding-block: 4px;
    transition: 0.15s ease-in-out;
}

.bet-list:hover {
    background-color: #36454F;
    color: white;
    border-radius: 10px;
}

.comment-container {
    cursor: pointer;
    color: rgb(83, 71, 214);
}

.comment-username {
    color: rgb(83, 71, 214);
    cursor: pointer;
}

.right-border::before {
    content: "";
    border: 1px solid rgb(178, 165, 165);
}

.edit-icon {
    position: absolute;
    bottom: 15px;
    left: 100px;
    cursor: pointer;
}

/* --------------Media Queries-------------------------- */
@media (max-width: 767px) {

    :root {
        --right-border: unset;
    }

    .blue,
    .green,
    .red,
    .orange {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0;
        box-shadow: 0px 2px #e2e1e1;
        /* border: 1px solid grey; */
    }

    .profile-name {
        text-align: center;
    }

    .parent {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        width: 100%;
        padding-top: 10px;
        gap: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .div1,
    .div2,
    .div3 {
        display: contents;
        box-shadow: unset;
    }

    .right-border {
        display: none;
    }
}

