* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --white: #fff;
    --grey: rgb(130, 130, 130);
    --black: #000;
}

p {
    margin: 0;
}

a:hover {
    text-decoration: none;
}

.flex {
    display: flex;
    align-items: center;
}

.wrapper {
    padding-inline: 15px;
}

.profile-wrapper {
    margin-bottom: 10px;
}

/* .logo {
    font-size: 48px;
    color: #069514;
} */

.nav-link {
    font-size: 32px;
    color: #333333;
}

.home-text-logo {
    font-size: 32px;
    width: 211px;
    /* height: 82px; */
    padding-block: 8px;
    background-color: unset !important;
    color: unset !important;
    /* margin-top: 27px; */
}

/* .profile-name {
    color: var(--black);
} */

.profile-pic {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 16px;
}

.menu {
    width: 243px;
}

.search-bar {
    border: 0;
}

.search-icon {
    position: relative;
    left: 0px;
}

.dashboard-feed-images {
    width: 100%;
}

.feed-image-wrapper {
    padding-bottom: 30px;
}

.right-side-column {
    color: var(--black);
    font-size: 16px;
}

.right-side-text {
    color: #828282;
}

.right-side-wrapper {
    margin-bottom: 19px;
}

.feed-image-section {
    box-shadow: 0px 0px 10px #a59c9c;
    border-radius: 24px;
    position: relative;
    /* height: 400px; */
    margin-bottom: 15px;
    overflow: hidden;
}

.feed-profile-image {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.chances-text {
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}
@media (max-width: 768px) {
    .chances-text {
        margin-right: 2px;
        font-size: 10px;
    }
}

.bet-btn {
    width: 97px;
    height: 32px;
    border-radius: 100px;
    border: none;
    color: white;
    transition: 0.25s ease-in;
    padding: 4px;
}

.bet-btn:hover {
    opacity: 0.7;
}

.bet-yes {
    background-color: #069514;
    margin-right: 16px;
}

.bet-no {
    background-color: #EB5757;
}

@media (max-width: 768px) {
    .bet-btn {
        width: 60px;
        height: 28px;
        font-size: 14px;
        padding: 2px;
    }
    
    .bet-yes {
        margin-right: 8px;
    }
}

.feed-float {
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
}

@media (min-width: 1150px) { /* Adjust the min-width value to your breakpoint */
    .feed-float {
        left: 29px;
    }
}
.feed-float-landing-page {
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
}

@media (min-width: 1150px) { /* Adjust the min-width value to your breakpoint */
    .feed-float-landing-page {
        left: 29%;
    }
}

.feed-float-border {
    border: 2px solid #069514;
    border-radius: 100px;
    padding: 4px 10px;
}

.custom-control-input:focus~.custom-control-label::before {
    border-color: #069514 !important;
    box-shadow: 0 0 0 0.2rem rgba(255, 47, 69, 0.25) !important;
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: #069514 !important;
    background-color: #069514 !important;
}

.custom-control-input:active~.custom-control-label::before {
    background-color: #069514 !important;
    border-color: #069514 !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #069514 !important;
}

.custom-control-input-green:not(:disabled):active~.custom-control-label::before {
    background-color: #069514 !important;
    border-color: #069514 !important;
}

.list-image {
    width: 25px;
    height: 25px;
}

.clickable-icon {
    cursor: pointer;
}

.achivements {
    display: none;
}

.like-icon-container {
    filter: invert(52%) sepia(88%) saturate(3279%) hue-rotate(162deg) brightness(95%) contrast(103%);
}

.profile-section-upper{
    display: none;
}

.profile-section-upper-tablet {
    display: none !important;
}


/* Styles for the modal */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000a3;
}

.modal-content {
    width: 700px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-close {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 20px;
    cursor: pointer;
}

.modal-bet-yes {
    background-color: #069514;
    border-radius: 50px;
    text-align: center;
    padding: 10px 40px;
    color: white;
    cursor: pointer;
}

.modal-bet-no {
    background-color: #EB5757;
    border-radius: 50px;
    text-align: center;
    padding: 10px 40px;
    color: white;
    cursor: pointer;
}

.bet-amount-section {
    padding: 30px;
}

.modal-input-container {
    height: 50px;
    background-color: #fff;
}

.bet-modal-input {
    outline: none;
    height: 85%;
    width: 100%;
}

.increase-amount {
    padding-inline: 5px;
}

/* .increase-amount:hover {
    padding: 5px 8px;
    background-color: #0695144a;
    cursor: pointer;
    border-radius: 10px;
} */

/* -------Modal----------------------------- */
.form-range::-webkit-slider-thumb {

    background: #069514;

}

.form-range::-moz-range-thumb {
    background: #069514;
}

.form-range::-ms-thumb {
    background: #069514;
}

@media (prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none;
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #069514;
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #E0E0E0;
    border-color: transparent;
    border-radius: 1rem;
}

.modal-btn {
    /* background-color: #069514; */
    padding: 20px 20px;
    width: 80%;
    color: white;
    border: none;
    border-radius: 50px;
    margin-top: 10px;
    transition: 0.3s ease;
}

.modal-btn:hover {
    /* background-color: #069514d4; */
}

.green-theme {
    background-color: #F0F9F1;
}

.red-theme {
    background-color: #FFF1F1;
}

.modalBtnGreen {
    background-color: #069514;
}

.modalBtnRed {
    background-color: #EB5757;
}

.modalBetYesToggle {
    border: 2px solid #069514;
    border-radius: 50px;
    text-align: center;
    padding: 10px 40px;
    cursor: pointer;
}

.modalBetNoToggle {
    border: 2px solid #EB5757;
    border-radius: 50px;
    text-align: center;
    padding: 10px 40px;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .modal {
        padding: 20px 20px;
    }

    .modal-bet-yes,
    .modal-bet-no {
        padding: 10px 20px;
    }

    .modal-btn {
        padding: 15px;
    }
}

/* ----------------------------- */

@media screen and (max-width: 1150px) {
    .feed-float {
        flex-direction: column;
    }

    .feed-float-border {
        width: 100%;
        padding: 0 5px;
    }

}

@media screen and (max-width: 769px) {
    .profile-section {
        display: none;
        
    }

    .rightside-section {
        display: none;
    }

    .profile-section-upper {
        display: unset;
    }

    .right-side-column {
        font-size: 12px;
    }

}

@media (max-width: 992px) {
    .col-md-2 {
        display: none !important;
    }

    .col-md-7 {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 825px) {

    .profile-section-upper-tablet {
        display: unset !important;
    }
}

/* @media (max-width: 992px) {
    .navbar-collapse {
        position: fixed;
        top: 50px;
        left: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        width: 75%;
        height: 100%;
    }

    .navbar-collapse.collapsing {
        left: -75%;
        transition: height 0s ease;
    }

    .navbar-collapse.show {
        left: 0;
        transition: left 300ms ease-in-out;
    }

    .navbar-toggler.collapsed ~ .navbar-collapse {
        transition: left 500ms ease-in-out;
    }
} */
