.flag {
    /* border-radius: 20px; */
    box-shadow: none
}

.flag-dropdown {
    border-bottom: 1px solid black;
    padding-right: 10px;
}

.inputStyle {
    background-color: #000;
}

.react-tel-input .form-control {
    width: 15vmin;
}

.react-tel-input .flag-dropdown {
    border-top: none;
    border-left: none;
    border-right: none;
}

.react-tel-input .form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    margin-left: 32px;
    padding-left: 10px;
}
