.reset-password-label {
    color: #4F4F4F;
}

.reset-password-label-color {
    color: #828282 !important;
    font-size: 16px;
}

.reset-password-img {
    padding: 50px !important;
}


.pwd-container-img {
    cursor: pointer;
    position: relative;
    width: 20px;
    right: 5%;
    bottom: 0.1rem;
    opacity: 0.5;
}