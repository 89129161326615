.flex {
    display: flex;
    align-items: center;
}

.question-label {
    /* padding-block: 10px; */
    background-color: #0d0d0d9c;
    /* opacity: 0.5; */
    position: absolute;
    left: 5px;
    color: white;
    padding: 10px;
    top: 10px;
    border-radius: 4px;
}

.question-label-detail {
   margin: "50px";
}
.question-profile {
    background-color: #0d0d0d9c;
    position: absolute;
    left: 5px;
    bottom: 10px;
    color: white;
    padding: 4px;
    border-radius: 4px;
}

.close-market-label {
    color: #069514;
    background-color: rgb(6 149 20 / 10%);
    padding: 2px 4px;
}

.chance-wrapper {
    font-size: 20px;
    border: 1px solid #E0E0E0;
    padding-inline: 4px;
    border-radius: 8px;
}

.question-section {
    border-radius: 24px;
    position: relative;
    height: 260px;
}

.b-yes {
    background-color: #069514;
    border-radius: 50px;
    padding: 10px 5px;
    text-align: center;
    padding: 6px 40px;
    color: white;
    cursor: pointer;
    transition: 0.25s ease-in-out;
}

.b-no {
    background-color: #EB5757;
    border-radius: 50px;
    padding: 10px 5px;
    text-align: center;
    padding: 6px 40px;
    color: white;
    cursor: pointer;
    transition: 0.25s ease-in-out;
}

.b-no:hover{
    opacity: 0.8;
}

.b-yes:hover{
    opacity: 0.8;
}
.replyBtn{
    cursor: pointer;
}

.sendBtn {
   cursor: pointer;
}

.question-description {
    padding: 0px 25px;
    margin-top: 25px;
    font-size: 15px;
    text-align: justify;
}

.collapse-container {
    margin-top: 20px;
    padding-inline: 10px;
}

.collapse-wrapper {
    color: #6D7175;
}

.custom-tab {
    background: transparent !important;
    border-bottom: 2px solid #069514 !important;
    border-radius: 0px !important;
}

.ql-container {
    height: 150px;
}

.toggle-menu-items {
    font-size: 14px;
}

#trades {
    font-size: 14px;
}

.option-theme-dark {
    color: white;
    background-color: black;
}

.option-theme-light {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
}

.market-bet-position {
    box-shadow: 0px 0px 6px #c7c5c5;
    font-size: 14px;
    border-radius: 10px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ccc;
}


.market-user-img{
    width: 36px;
    height: 36px;
}

.multiple-options{
   display: flex;
   align-items: center;
   flex-wrap: wrap;
}

.multiple-options-btn{
    padding: 4px 10px;
    border: none;
    background: #069514db;
    color: white;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.25s ease-in-out;
    width: calc(50% - 20px);
}

.multiple-options-btn:hover{
    opacity: 0.8;
    background-color: #888;
}

/* Adjust the styles for smaller screens */
@media screen and (max-width: 767px) {

    .table th,
    .table td {
        font-size: 14px;
        padding: 5px;
    }
}

@media (max-width: 840px) {
    .question-label {
        font-size: 12px;
    }

    .question-profile>div {
        font-size: 12px;
        padding: 2px;
    }

    .question-description {
        font-size: 13px;
    }

    .chance-wrapper {
        font-size: 16px;
    }

    .activity-stats {
        justify-content: center !important;
        font-size: 15px;
    }

    .b-yes {
        background-color: #069514;
        border-radius: 50px;
        padding: 6px 20px;
        text-align: center;
        gap: 6px;
    }

    .b-no {
        background-color: #EB5757;
        border-radius: 50px;
        padding: 6px 20px;
        text-align: center;
        gap: 6px;
    }
}