.flex {
    display: flex;
    align-items: center;
}

.leagueSubHeader {
    /* width: 85%; */
}

.leagueSubHeader>h5 {
    width: 80%;
    font-size: 17px;
}

.prizesText {
    color: #069514;
    text-decoration: underline;
    cursor: pointer;
}

.schedule {
    background-color: #F0F9F1;
}

.league-ranking-profile-index {
    width: 10px;
}

.league-ranking-profile {
    width: 36px;
    height: 36px;
}

@media screen and (max-width: 767px) {
    .schedule-container {
        padding-left: 15px;
    }

    .schedule-wrapper {
        flex-direction: column;
        gap: 15px;
        padding: 0;
    }

    .schedule{
        padding-inline: 20px !important;
    }

    .leagueSubHeader {
        justify-content: center !important;
        text-align: justify;
    }
}