/* .dark{
    color: white;
 }

.light{
    color: black;
}

.input-dark{
    color: white !important;
    background-color: black !important;
}

.input-light{
    color: black !important;
    background-color: white !important;
} */

.flex {
    display: flex;
    align-items: center;
}

.right-side-wrapper{
    cursor: pointer;
}

.portfolio-percentage{
    border: 2px solid #BDBDBD;
    padding: 3px 4px;
    border-radius: 50px;
    color: black;
    margin-inline: 3px;
}

.blueColor{
    color: #2F6AEA;
}

.streak-modal-img{
    width: 38px;
    height: 38px;
}

@media (max-width: 768px) {
    .right-side {
        display: flex;
    }
}