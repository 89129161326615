.market-bet-btn {
    width: 47px;
    height: 29px;
    padding: 4px 4px;
    border-radius: 15px;
    border: 1px solid #069514;
    color: #069514;
    transition: 0.2s ease-in-out;
}

.market-bet-btn:hover{
    background-color: #a0dfa8d8;
}

.market-profile-img{
    width: 36px;
    height: 36px;
}