.profile-image {
    width: 55px;
    height: 55px;
    border-radius: 100%;
}

.progress {
    height: 20px;
}

.change-view-img {
    cursor: pointer;
    transition: 0.3s ease;
}

.change-view-img:hover {
    filter: invert(90%) sepia(2%) saturate(635%) hue-rotate(131deg) brightness(112%) contrast(86%);
} 