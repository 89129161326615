.container .nav-pills a {
    text-decoration: none;
    color: gray;
}

.text-color {
    color: #A3A3A3;
}

.more-btn {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-inline: 8px;
    border: 1px solid #069514;
    border-radius: 6px;
    text-decoration: none;
}

.create-market {
    /* width: 509px;
    height: 48px; */
    padding: 1vw;
    width: 100%;
    background: #069514;
    border-radius: 100px;
    text-align: center;
    border: none;
    color: white;
    margin-top: 37px;
}

.accordion-wrapper {
    width: 200px;
}

.accordion-btn {
    /* background-color: white; */
    border: 1px solid #069514;
    color: #069514;
    border-radius: 8px;
}

.accordion-btn:hover {
    border: 1px solid #069514;
    color: #069514;
}

input::placeholder {
    color: #BDBDBD !important;
    opacity: 0.5;
    font-size: 14px;
}

.rte-placeholder::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    color: #BDBDBD !important;
    opacity: 0.7;
    font-size: 14px;
}

.custom-input {
    height: 40px;
    border-radius: 8px;
    border: 1px solid #BDBDBD;
    font-size: 14px;
    padding: 3px 6px;
}

/* .Active {
    background-color: #333333e0;
    color: white !important;
    border-radius: 6px;
} */

.custom-tab {
    background: transparent !important;
    color: white !important;
    border-bottom: 2px solid #069514 !important;
    border-radius: 0px !important;
}


.InActive {
    /* background-color: rgba(255, 255, 255, 0.678) !important; */
    color: rgb(26, 25, 25);
    border-radius: 6px;
}

.create-market-text {
    font-size: 32px;
    width: 211px;
    height: 70;
    background-color: unset !important;
    color: unset !important;
}

/* Image upload */
.app {
    text-align: center;
    padding: 20px;
}

.image-selector {
    margin-top: 20px;
}

.selected-image {
    margin-top: 20px;
}

.selected-image img {
    max-width: 70%;
    height: 300px;
    border: 1px solid #ccc;
    border-radius: 10px;
}


/* Make react quill expandable */
.expandable-quill-editor {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    transition: height 0.2s;
}

.ql-container {
    border: none;
}

.ql-editor {
    min-height: 100px;
    padding: 10px;
    outline: none;
}


/* Loader */
.loader-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Rotate arrow */
/* .toggle-up {
    animation-name: toggle-up;
    animation-delay: 0.25s;
    animation-duration: 0.75s;
    animation-fill-mode: forwards;
  } */
.toggle-down {
    animation-name: toggle-down;
    animation-delay: 0.15s;
    animation-duration: 0.55s;
    animation-fill-mode: forwards;
}

/*animations*/
/* @keyframes toggle-up {
    100% {
      transform: rotate(180deg);
    }
  } */
@keyframes toggle-down {
    100% {
        transform: rotate(180deg);
    }
}