*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.dark {
    color: white;
}

.light {
    color: black;
}

.input-dark {
    color: white !important;
    background-color: black !important;
}

.input-light {
    color: black !important;
    background-color: white !important;
}