.notification-card{
    background-color: #F8F8F8;
    border-radius: 10px;
}

.toggle-menu-items {
    font-size: 16px;
}

.chatImg{
    height: 25px;
}

.newCommens{
    cursor: pointer;
}

.newCommens img{
    margin-left: 12px;
}

.balanceChangesLabel, .general{
    font-size: 22px;
    margin-top: 35px;
}

.iconColor{
    filter: invert(99%) sepia(3%) saturate(833%) hue-rotate(156deg) brightness(117%) contrast(100%);
}

@media (max-width: 767px){
    .toggle-menu-items{
        justify-content: center !important;
        font-size: 14px;
    }

    .notification-card{
        font-size: 14px;
    }
}