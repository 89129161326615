.menu-items {
  padding-right: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-block: 10px;
  padding-inline: 9px;
  margin-block: 4px;
}

/* .menu-items:hover {
    background-color: #0695140e;
    border-radius: 30px;
    color: #0695140e;
} */

.menu-items p:hover {
  color: #069514;
}

.menu-items p {
  font-size: 18px;
  margin-left: 16px;
  padding-inline: 10px;
}

.menu-items img {
  width: 21.67px;
  height: 23.83px;
}

.create-market-btn {
  display: block !important;
  /* width: 243px !important; */
  height: 50px !important;
  padding-inline: auto !important;
  border-radius: 100px !important;
  border: 1px solid #069514 !important;
  color: #069514 !important;
  position: relative;
  left: 0px !important;
}

.create-market-btn:hover {
  background-color: #06951444;
  transition: 0.2s ease-in;
}

.SidebarActive {
  background-color: #0695142e !important;
  background-color: #fff;
  border-radius: 4px;
  color: #069514 !important;
  /* filter: invert(36%) sepia(95%) saturate(708%) hue-rotate(83deg) brightness(95%) contrast(103%); */
  position: relative;

}

.navIcon {
  /* filter: invert(36%) sepia(95%) saturate(708%) hue-rotate(83deg) brightness(95%) contrast(103%); */
  filter: invert(100%) sepia(4%) saturate(10%) hue-rotate(194deg) brightness(102%) contrast(102%);
}

.SidebarInActive {
  /* background-color: white; */
  border-radius: 4px;
}

.SidebarActiveBtn {
  background-color: rgba(160, 217, 166, 0.204) !important;
  border-radius: 4px;
  color: #069514 !important;
  filter: invert(36%) sepia(95%) saturate(708%) hue-rotate(83deg) brightness(95%) contrast(103%);
  position: relative;

}

.SidebarInActiveBtn {
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
}

.BottombarActive {
  background-color: rgba(160, 217, 166, 0.204) !important;
  border-radius: 4px;
  color: #069514 !important;
  filter: invert(36%) sepia(95%) saturate(708%) hue-rotate(83deg) brightness(95%) contrast(103%);
  padding: 10px;
}

.BottombarInActive {
  background-color: white;
  border-radius: 4px;
}

.SidebarActive::before {
  content: '';

  /* Absolutely positions the underline relative to the button. */
  position: absolute;

  /* This aligns the underline so that it is at the bottom of 
       the button. */
  bottom: 0;
  right: 0;
  width: 5px;
  height: 100%;
  background: #069514;
  border-radius: 10px;
}

a {
  color: #828282;
}

/* Bottom navigation */
.mobile-nav {
  background-color: #fff;
  position: fixed;
  bottom: -4px;
  height: 58px;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: space-around;
  z-index: 100;
  border-top: 0.5px solid rgba(0, 0, 0, 0.383);
}

.block-icon img {
  width: 20px;
}

.sidemenu-logo {
  display: none;
}

.wrapper {
  position: relative;
}

.menu-toggle {
  display: none;
}

.menu-icon {
  display: none;
  position: absolute;
  top: 25px;
  right: 10px;
  cursor: pointer;
  z-index: 1000;
  background-color: #fff;
}

.menu-icon span {
  display: block;
  width: 24px;
  height: 3px;
  background-color: #000;
  margin-bottom: 5px;
}

.menu-items-container {
  display: flex;
  flex-direction: column;
}

/* ------Dark Mode Section------ */
.Dark {
  background-color: #000000;
  color: #fff;
}

.Light {
  background-color: #fff;
  color: #333;
}

.BottomDark {
  background-color: #ffffff;
  color: #000000;
}

.BottomLight {
  background-color: #fff;
  color: #070707;
}
/* ---------------------------------------- */

/* .menu-open {
  overflow: hidden;
} */

.Sidebar-Active-Market-Btn {
  background-color: #0695142e !important;
  border-radius: 4px;
  color: #069514 !important;
}

.Sidebar-In-Active-Market-Btn {
  background-color: #0695142e;
  border-radius: 4px;
  color: #069514;
}

/* bottom menu font size */
.bottom-menu-label {
  font-size: 12px;
}

/* ----------------------------------------Media queries---------------------------------------- */

/* Bottom navigation bar */
@media screen and (min-width: 850px) {
  .mobile-nav {
    visibility: hidden;
  }
}

/* @media screen and (min-width: 992px) {
    .wrapper-side {
        display: unset;
    }

    .navbar-container {
        align-items: flex-start;
    }

} */

@media (max-width: 957px) {
  .menu-items-container {
    display: none;
    position: fixed;
    top: -10px;
    z-index: 100;
    /* background-color: #fff; */
    /* border: 1px solid red; */
    width: 70vw;
    height: 100vh;
    margin: 10px -39px;
    padding: 50px;
    padding-left: 18%;
    gap: 10px;
    box-shadow: 0px 0px 20px #E0E0E0;
  }

  .menu-icon {
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    right: 7%;
    top: 28%;
  }

  .menu-toggle:checked~.menu-items-container {
    display: flex;
    flex-direction: column;
  }

  .menu-toggle:checked~.menu-icon span:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .menu-toggle:checked~.menu-icon span:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle:checked~.menu-icon span:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .menu-icon {
    display: block;
  }

  .sidemenu-logo {
    display: block;
  }

  .sidemenu-logo-color {
    filter: invert(100%) sepia(4%) saturate(10%) hue-rotate(194deg) brightness(102%) contrast(102%);
  }

  .menu-items img {
    width: 30px;
    height: 30px;
  }

  .menu-items p {
    font-size: 25px;
  }

  .create-market-btn {
    font-size: 20px;
  }

  .profile-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between !important;
  }
}

/* -------------------------------------------------------------------- */
@media (max-width: 767px) {
  .menu-items-container {
    display: none;
    position: fixed;
    top: -10px;
    z-index: 100;
    /* background-color: #fff; */
    /* border: 1px solid red; */
    width: 70vw;
    height: 100vh;
    margin: 10px -39px;
    padding: 22px;
    gap: 10px;
    box-shadow: 0px 0px 20px #E0E0E0;
  }

  .menu-items p {
    font-size: 20px;
  }

  .logo-bottom-margin {
    margin-bottom: 45px;
  }

  .logo {
    position: fixed;
    z-index: 100;
  }
}

/* -------------------------------------------------------------------- */

@media (max-width: 850px) {
  .create-market-btn {
    width: 100% !important;
    margin-bottom: 20vh;
  }


  .logo {
    position: fixed;
    z-index: 100;
    background-color: white;
    margin-top: 15px;
  }

  .logo-bottom-margin {
    margin-bottom: 45px;
  }
}