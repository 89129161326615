.flex {
    display: flex;
    align-items: center;
}

.sitemap-card {
    box-shadow: 0px 0px 20px #a59c9c40;
    padding: 15px;
    border-radius: 6px;
}

.sitemap-card-img {
    width: 49px;
    height: 49px;
    border-radius: 100px;
    background-color: #82828238;
    padding: 4px;
}

.sitemap-wrapper div{
    color: #000;
}

@media screen and (max-width: 767px) {

    .sitemap-wrapper {
        flex-direction: column;
        gap: 20px;
    }

}