.flex {
    display: flex;
    align-items: center;
}

.newsletter-img {
    /* width: 35vw;
    height: 35vh; */
}

.newsletter-label {
    font-size: 1.5rem;
}

.subscribe-input {
    height: 50px;
    border: 1px solid #069514;
    border-radius: 8px 0px 0px 8px;
    padding: 0 10px;
    outline: #069514;
    width: 425px;
}

.subscribe-btn {
    height: 50px;
    border: none;
    background-color: #069514;
    color: white;
    padding-inline: 35px;
    border-radius: 0px 8px 8px 0px;
}

.newsletter-container{
    background-color: #0000000a;
    border-radius: 10px;
}

@media screen and (max-width: 767px) {

    .subscribe-container{
        flex-wrap: wrap;
        gap: 20px;
    }

    .subscribe-input, .subscribe-btn{
        border-radius: 10px;
    }

    .newsletter-label{
        font-size: 1.2rem;
        text-align: center;
    }
}

@media screen and (max-width: 820px) {
    .subscribe-input{
        width: unset;
    }
}