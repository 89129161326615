@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Ubuntu&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: "Poppins", sans-serif;
}


:root {
    --primary-text-color: #183b56;
}

.signup-label {
    color: #4F4F4F;
}

.label-color {
    color: #828282 !important;
    font-size: 16px;
}

.already {
    color: #969696;
    font-family: 'Ubuntu';
}

.agree-text {
    font-family: 'Poppins';
    color: #7E7E80;
    margin-bottom: 0;
}

#flexCheckDefault {
    height: 24px;
    width: 24px;
    border: 1.5px solid #828282;
    position: static;
}

.main-container {
    /* padding-block: 5vh; */
}

/* Login page */

.forgot-text {
    color: #7E7E80 !important;
    font-family: 'Poppins';
}

/* Select-coutry */
.select-coutry {
    outline: none;
}
 