@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Ubuntu&display=swap');

.signin-label {
    color: #4F4F4F;
}

.label-color {
    color: #828282 !important;
    font-size: 16px;
}

.already {
    color: #969696;
    font-family: 'Ubuntu';
}

.main-container {
    /* padding-block: 5vh; */
}

/* Login page */

.forgot-text {
    color: #7E7E80 !important;
    font-family: 'Poppins';
}