.flex {
    display: flex;
    align-items: center;
}

.medal-icon {
    width: 30px;
}

.leaders-img {
    width: 35px;
}

@media screen and (max-width: 767px) {
    .traders-creator-container {
        flex-direction: column;
        gap: 30px !important;
     }
}