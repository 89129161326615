.input-spacer {
    padding-left: 46px !important;
}

.bet-spacer {
    padding-left: 86px !important;
}

.btn-refresh {
    width: 43px;
    height: 43px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.permanent-delete-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    border: 1px solid #EB5757;
    border-radius: 8px;
    color: #EB5757;
    cursor: pointer;
    transition: 0.2s ease;
    font-size: 15px;
}

.permanent-delete-btn:hover {
    color: #EB5757;
    background-color: #f59d9d2e;
}

.update-btn-wrapper {
    margin-top: 69px;
}

.update-btn {
    padding: 12px 60px;
    background: #069514;
    border-radius: 100px;
    width: 400px;
    height: 55px;
    color: white;
    transition: 0.3s ease-in-out;
}

.update-btn:hover {
    background-color: #0695148a;
    color: white;
}

.image-upload>input {
    display: none;
}

.image-upload .avatar {
    min-width: 130px;
    min-height: 130px;
    cursor: pointer;
    border-radius: 100px;
    background-size: contain;
    max-width: 130px;
    max-height: 130px;
}

.avatar:hover {
    opacity: 0.7;
    transition: 0.3s all;
}

input::file-selector-button {
    background-image: linear-gradient(to right,
            #ff7a18,
            #af002d,
            #319197 100%,
            #319197 200%);
    background-position-x: 0%;
    background-size: 200%;
    border: 0;
    border-radius: 8px;
    color: #fff;
    padding: 0.50rem 1.25rem;
    text-shadow: 0 1px 1px #333;
    transition: all 0.25s;
    cursor: pointer;
}

input::file-selector-button:hover {
    /* background-position-x: 100%; */
    /* transform: scale(1.1); */
    opacity: 0.8;
}

.document-icon {
    width: 25px;
    height: 25px;
}

.contact-icon {
    width: 25px;
    height: 25px;
}

.edit-pencil {
    position: absolute;
    bottom: 12px;
    right: 5px;
    cursor: pointer;
}

.show-error-label {
    color: red;
    padding-left: 43px;
    font-size: 15px;
}

.status-icon {
    width: 35px;
}

.theme1 {
    color: #fff !important;
}

.theme2 {
    color: black !important;
}

.edit-profile-text {
    font-size: 32px;
    width: 255px;
    height: 70px;
}

.edit-profile-text:hover {
    background-color: unset !important;
    color: unset !important;
}

.contact-input-wrapper {
    display: flex;
    align-items: flex-end;
    padding-left: 47px;
}

.contact-input-wrapper input {
    padding-left: 8px;
}

.country-code-wrapper {
    padding-bottom: 5px;
    font-size: 14px;
}

@media screen and (max-width: 992px) {

    .show-error-label {
        color: red;
        padding-left: 43px;
        font-size: 9px;
    }

}

@media screen and (max-width: 767px) {

    .show-error-label {
        color: red;
        padding-left: 43px;
        font-size: 14px;
    }

    .create-client-header {
        font-size: 28px !important;
    }
}

.create-client {
    border: none;
    padding: 10px 20px;
    background-color: #1683FB;
    color: white;
    border-radius: 6px;
    transition: 0.2s ease-in-out;
    width: fit-content;
    cursor: pointer;
    width: 120px;
    text-align: center;
}

.complycube-img {
    width: 20px;
    height: 20px;
    margin-left: 6px;
}

.create-client-header {
    font-size: 38px;
}

.modal-label-width {
    width: 45%;
}

.allowed-file-types {
    font-size: 12px;
    color: gray;
}

.red-outline {
    border: 2px solid red;
}

.step-label {
    padding: 10px 20px;
    background-color: #80808057;
    border-radius: 100px;
}