.verify-wrapper {
    font-family: 'Poppins' !important;
}

.verify-label {
    color: #4F4F4F;
}

.forgot-email-color {
    color: #828282 !important;
    font-size: 16px;
}

.back-text {
    margin-right: 10px;
    color: #969696;
}

#partitioned {
    padding-left: 10px;
    letter-spacing: 42px;
    border: 0;
    background-image: linear-gradient(to left, #069514 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 50px 2px;
    background-repeat: repeat-x;
    background-position-x: 35px;
    width: 220px;
    min-width: 220px;
    outline: none;
}

#divInner {
    left: 0;
    position: sticky;
}

#divOuter {
    width: 200px;
    overflow: hidden;
}

.outer-container {
    position: relative;
    width: 100%;
    height: 150px;
 }

.inner-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
}